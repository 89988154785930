import React from 'react'
var CK = require('./CoreKit.jsx');

export default class CoreButton extends React.Component {
  render() {
    if (this.props.type && this.props.type == "submit") {
      return (
          <button {...this.props} className={ CK.Classes(this.props.className, "_core_button", "waves-effect", "waves-light", "btn") }>
            {this.props.children}
          </button>
      );
    }
    return (
        <a {...this.props} className={ CK.Classes(this.props.className, "_core_button", "waves-effect", "waves-light", "btn") }>
          {this.props.children}
        </a>
    );
  }
}