import React from 'react'
var CK = require('./CoreKit.jsx');

export default class CoreImageView extends React.Component{
    render() {
        return (
            <CK.View {...this.props} className={ CK.Classes(this.props.className, "_core_image_view") }>
                <img src={this.props.children} />
            </CK.View>
        );
    }
}