import React from 'react'
var CK = require('./CoreKit.jsx');

export default class CoreFormAPIView extends React.Component{
  render() {
    return (
        <CK.View className={ CK.Classes(this.props.className) }>
          <div className={CK.Classes(!this.props.api.busy ? "gone" : "")}>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={CK.Classes(!this.props.api.success ? "gone" : "")}>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={CK.Classes("text_small error", !this.props.api.error ? "gone" : "")}>{this.props.api.error_message}</div><br/>
          {!this.props.api.success && !this.props.api.busy ? this.props.children : ""}
        </CK.View>
    );
  }
}