export {default as Classes} from './CoreClass.jsx'
export {default as View} from './CoreView.jsx'
export {default as ImageView} from './CoreImageView.jsx'
export {default as Button} from './CoreButton.jsx'
export {default as Window} from './CoreWindow.jsx'
export {default as Controller} from './CoreController.jsx'
export {default as Localize} from './CoreLocalization.jsx'
export {default as FormAPIView} from './CoreFormAPIView.jsx'
export {default as FormAPIViewController} from './CoreFormAPIViewController.jsx'


import Classes from './CoreClass.jsx'
import View from './CoreView.jsx'
import ImageView from './CoreImageView.jsx'
import Button from './CoreButton.jsx'
import Window from './CoreWindow.jsx'
import Controller from './CoreController.jsx'
import Localize from './CoreLocalization.jsx'
import FormAPIView from './CoreFormAPIView.jsx'
import FormAPIViewController from './CoreFormAPIViewController.jsx'

var Alt = require('./CoreAltDispatcher.jsx');

window.CK = {
    "Classes" : Classes,
    "View" : View,
    "ImageView" : ImageView,
    "Button" : Button,
    "Window" : Window,
    "Controller" : Controller,
    "Localize" : Localize,
    "FormAPIView" : FormAPIView,
    "FormAPIViewController" : FormAPIViewController,
    "Alt" : Alt,
}