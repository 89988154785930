import React from 'react'
var CK = require('./CoreKit.jsx');

export default class CoreFormAPIViewController extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      url: '',
      method: '',
      body: '',
      busy: false,
      success: false,
      error: false,
      error_message: " "
    }
  }

  render() {
    return (
        <div>{this.props.children}</div>
    );
  }

  onFormSubmit(e) {
    var self = this;
    if (e != null) e.preventDefault();
    if (self.state.busy) return;
    self.state.busy = true;
    fetch(self.state.url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: self.state.method,
      body: self.state.body,
    }).then((res) => { return res.json() })
        .then((json) => {
          if (json.status == "error") {
            self.setState({
              busy: false,
              success: false,
              error: true,
              error_message: json.message
            });
            self.onFormError(json);
          } else {
            self.setState({
              busy: false,
              success: true,
              error: false,
              error_message: " "
            });
            self.onFormSuccess(json);
          }
        })
        .catch((error) => {
          self.setState({
            busy: false,
            success: false,
            error: true,
            error_message: "Network Error. Please check your network settings."
          });
          self.onFormNetworkError(error);
        });
  }

  onFormSuccess(json) {}

  onFormError(json) {}

  onFormNetworkError(error) {}
}

//<LoginView api={this.state} onFormSubmit={this.onFormSubmit.bind(this)} className={CK.Classes(this.props.className, "login_view_controller")} />
